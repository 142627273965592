const comMain2 = () => {

  const jsComSecMain2 = document.getElementById('jsComSecMain2');
  if (jsComSecMain2) {



    // let sW = window.innerWidth;
    // let sH = window.innerHeight;

    const jsComSecMain2 = document.getElementById("jsComSecMain2");
    const copyWrap = jsComSecMain2.querySelector('.js-copy-wrap');


    setTimeout(() => {

      copyWrap.classList.add('act')

    }, 500);



  }

};
export default comMain2;