const topRecordSlide = (() => {

  const pageTop = document.getElementById('pageTop');
  if (pageTop) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    if (sW <= 750) {

      const swiper = new Swiper('#jsSecRecord .js-swiper', {
        effect: 'slide', // フェードモードにする（デフォルトは 'slide'）
        // fadeEffect: {
        //   crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
        // },

        // slidesPerView: 'auto', // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
        slidesPerView: 1.4, // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
        spaceBetween: 30, // スライド間の余白（px）
        centeredSlides: true, // アクティブなスライドを中央に配置する

        // loop: true,  // 無限ループさせる
        // loopAdditionalSlides: 1, // 無限ループさせる場合に複製するスライド数

        speed: 500, // スライドアニメーションのスピード（ミリ秒）

        // autoplay: { // 自動再生させる
        //   delay: 3000, // 次のスライドに切り替わるまでの時間（ミリ秒）
        //   disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない
        //   waitForTransition: false, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
        // },

        // grabCursor: true, // PCでマウスカーソルを「掴む」マークにする
        watchSlidesProgress: true, // スライドの進行状況を監視する

        pagination: {
          el: '#jsSecRecord .swiper-pagination-area', // ページネーション要素のクラス
          clickable: true, // クリックによるスライド切り替えを有効にする
          type: 'fraction' // 'bullets'（デフォルト） | 'fraction' | 'progressbar'
        },

        navigation: {
          nextEl: '#jsSecRecord .swiper-btn-next', // 「次へ」ボタン要素のクラス
          prevEl: '#jsSecRecord .swiper-btn-prev', // 「前へ」ボタン要素のクラス
        },

        // scrollbar: {
        //   el: '.swiper-scrollbar', // スクロールバー要素のクラス
        // },

        // thumbs: {
        //   swiper: mySwiper2 // サムネイルのスライダーのインスタンス名
        // },

        // breakpoints: { // ブレークポイント
        //   600: { // 画面幅600px以上で適用
        //     slidesPerView: 2,
        //   },
        //   1025: { // 画面幅1025px以上で適用
        //     slidesPerView: 4,
        //     spaceBetween: 32,
        //   }
        // },

        on: { // イベントを登録する
          slideChange: (swiper) => {
            // console.log('Slide index changed to: ' + (swiper.realIndex + 1));
            let slides = document.querySelectorAll('#jsSecRecord .swiper-slide');
            // const jsSecRecord = document.getElementById("jsSecRecord");
            // const recordArea = document.querySelectorAll('#jsSecRecord .js-record-area');
            const dotBoxs = document.querySelectorAll('#jsSecRecord .js-dot-box-sp');

            slides.forEach(element => {
              element.classList.remove('act')
            });
            dotBoxs.forEach(element => {
              element.classList.remove('hover')
            });
            setTimeout(() => {
              slides[swiper.realIndex].classList.add('act')
              dotBoxs[swiper.realIndex].classList.add('hover')
            }, 500);
          },
        },
      });


      const paginationTxt = document.querySelector('#jsSecRecord .swiper-pagination-area');
      // console.log(paginationTxt.innerHTML);
      let result = paginationTxt.innerHTML.replace(' / ', ' - ');
      // console.log( result );
      paginationTxt.innerHTML = result;

    }



  }

})()
export default topRecordSlide;