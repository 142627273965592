const businessSideNav = (() => {

  const pageBusiness = document.getElementById('pageBusiness');
  if (pageBusiness) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    let navWrap
    let setStart
    if (sW > 750) {
      let jsSecNav = document.getElementById("jsSecNav");
      navWrap = jsSecNav.querySelector('.js-nav-wrap');
      setStart = "top top"
    } else {
      let jsSecAnker = document.getElementById("jsSecAnker");
      navWrap = jsSecAnker.querySelector('.js-anker-wrap');
      let headerH = document.getElementById('jsHeader').clientHeight;
      setStart = `${headerH * -1}px top`
    }

    const jsSecCntntRo = document.getElementById("jsSecCntntRo");

    const ank1 = document.getElementById("ank1");
    const ank2 = document.getElementById("ank2");
    const ank3 = document.getElementById("ank3");
    const ank4 = document.getElementById("ank4");


    document.addEventListener("DOMContentLoaded", () => {

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: navWrap,
          pin: true,
          start: setStart,
          endTrigger: jsSecCntntRo,
          end: "bottom bottom",
          pinSpacing: false,
          // markers: true,
        },
      })

      ScrollTrigger.create({
        trigger: ank1,
        start: 'top 1px',
        endTrigger: 'body',
        end: 'bottom top',
        toggleClass: { targets: navWrap, className: "actAnk1" },
        // markers: true,
      });
      ScrollTrigger.create({
        trigger: ank2,
        start: 'top 1px',
        endTrigger: 'body',
        end: 'bottom top',
        toggleClass: { targets: navWrap, className: "actAnk2" },
        // markers: true,
      });
      ScrollTrigger.create({
        trigger: ank3,
        start: 'top 1px',
        endTrigger: 'body',
        end: 'bottom top',
        toggleClass: { targets: navWrap, className: "actAnk3" },
        // markers: true,
      });
      ScrollTrigger.create({
        trigger: ank4,
        start: 'top 1px',
        endTrigger: 'body',
        end: 'bottom top',
        toggleClass: { targets: navWrap, className: "actAnk4" },
        // markers: true,
      });


      let time = 0
      const frame = () => {
        time = time + 1
        // console.log(time);
        if (time % 200 == 0) {
          tl.scrollTrigger.refresh()
        }
        window.requestAnimationFrame(frame);
      }
      window.requestAnimationFrame(frame);

    });


  }

})()
export default businessSideNav;