const topInfoModal = (() => {

  const pageTop = document.getElementById('pageTop');
  if (pageTop) {



    MicroModal.init({
      disableScroll: true,
      disableFocus: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      // onShow: modal => console.info(`${modal.id} is shown`), // [1]
      // onClose: modal => console.info(`${modal.id} is hidden`), // [2]
    });



  }

})()
export default topInfoModal;