const topRecordLine = (() => {

  const pageTop = document.getElementById('pageTop');
  if (pageTop) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    if (sW > 750) {

      const jsSecRecord = document.getElementById("jsSecRecord");
      const recordArea = jsSecRecord.querySelectorAll('.js-record-area');
      const dotBox = jsSecRecord.querySelectorAll('.js-dot-box');


      recordArea.forEach((element, index) => {

        element.addEventListener('mouseover', function () {
          dotBox[index].classList.add('hover')
        });

        element.addEventListener('mouseleave', function () {
          dotBox[index].classList.remove('hover')
        });

      });


    }



  }

})();
export default topRecordLine;