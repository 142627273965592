const comAct50 = (() => {

  const elem = document.querySelectorAll('.js-com-act-50');
  if (elem.length > 0) {


    for (let i = 0; i < elem.length; i++) {

      ScrollTrigger.create({
        trigger: elem[i],
        start: "0% 50%",
        toggleClass: { targets: elem[i], className: "act" },
        once: true,
        // markers: true,
      });

    }


  }

})();
export default comAct50;