const comMain = () => {

  const jsComSecMain = document.getElementById('jsComSecMain');
  if (jsComSecMain) {



    const jsComSecMain = document.getElementById("jsComSecMain");
    const copyWrap = jsComSecMain.querySelector('.js-copy-wrap');

    setTimeout(() => {
      copyWrap.classList.add('act')
    }, 500);



  }
};
export default comMain;