const comSwipeIcon = (() => {

  // <div class="com-swipe-icon-box js-com-swipe-icon">
  //   <div class="icon">
  //     <span class="material-symbols-outlined">swipe</span>
  //   </div>
  //   <p class="txt">左右にスワイプして<br>ご覧ください</p>
  // </div>

  const trigger = document.querySelectorAll('.js-com-swipe-icon-trigger');
  const icon = document.querySelectorAll('.js-com-swipe-icon');
  let sW = window.innerWidth;

  if (trigger.length > 0 && icon.length > 0) {


    for (let i = 0; i < trigger.length; i++) {

      if (sW > 750) {

        trigger[i].addEventListener('mouseover', () => {
          icon[i].style.display = 'none'
        }, false);

      } else {

        trigger[i].addEventListener('click', (event) => {
          icon[i].style.display = 'none'
        });

        trigger[i].addEventListener('touchstart', (event) => {
          icon[i].style.display = 'none'
        });

      }

    }


  }

})();
export default comSwipeIcon;