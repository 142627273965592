const topRecordFead = (() => {

  const pageTop = document.getElementById('pageTop');
  if (pageTop) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    if (sW > 750) {

      const jsSecRecord = document.getElementById("jsSecRecord");
      const recordWrap = jsSecRecord.querySelector('.record-wrap');
      const recordArea = jsSecRecord.querySelectorAll('.js-record-area');
      const lineWrap = jsSecRecord.querySelector('.js-line-wrap');


      ScrollTrigger.create({
        trigger: recordWrap,
        start: "0% 80%",
        once: true,
        onEnter: () => {

          for (let i = 0; i < recordArea.length; i++) {
            // console.log(i); //0,1,2
            (function (pram) {
              setTimeout(function () {
                // console.log(recordArea[pram]); //1,2,3
                recordArea[pram].classList.add('fead')
              }, pram * 600);
            })(i);
          };

          setTimeout(() => {
            lineWrap.classList.add('fade')
          }, 3000);

        },

        // markers: true,
      });


    }



  }

})();
export default topRecordFead;