const aboutHistoryScroll = (() => {

  const pageAbout = document.getElementById('pageAbout');
  if (pageAbout) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    const jsSecHistory = document.getElementById("jsSecHistory");
    // const dataArea = jsSecHistory.querySelector('.js-data-area');
    const dataArea = jsSecHistory.querySelector('.js-scroll-box');
    const dataBoxs = jsSecHistory.querySelectorAll('.js-data-box');
    const panels = jsSecHistory.querySelectorAll('.js-panel');

    let gap

    if (sW > 750) {
      gap = dataArea.clientHeight / 2;
    } else {
      gap = 0
    }


    panels[0].classList.add('act')


    dataArea.addEventListener('scroll', () => {
      let dataAreaY = dataArea.getBoundingClientRect().top + window.scrollY;
      // console.log(dataAreaY);
      var scrollV = dataArea.scrollTop + dataAreaY;
      // console.log(scrollV);
      let dataBoxs0Y = dataBoxs[0].getBoundingClientRect().top + window.scrollY + dataArea.scrollTop;
      let dataBoxs1Y = dataBoxs[1].getBoundingClientRect().top + window.scrollY + dataArea.scrollTop;
      let dataBoxs2Y = dataBoxs[2].getBoundingClientRect().top + window.scrollY + dataArea.scrollTop;
      let dataBoxs3Y = dataBoxs[3].getBoundingClientRect().top + window.scrollY + dataArea.scrollTop;
      let dataBoxs4Y = dataBoxs[4].getBoundingClientRect().top + window.scrollY + dataArea.scrollTop;
      let dataBoxs5Y = dataBoxs[5].getBoundingClientRect().top + window.scrollY + dataArea.scrollTop;
      // console.log(dataBoxs5Y - gap);

      panels.forEach(element => {
        element.classList.remove('act')
      });

      if (scrollV > dataBoxs5Y - gap) {
        panels[5].classList.add('act')
      } else if (scrollV > dataBoxs4Y) {
        panels[4].classList.add('act')
      } else if (scrollV > dataBoxs3Y) {
        panels[3].classList.add('act')
      } else if (scrollV > dataBoxs2Y) {
        panels[2].classList.add('act')
      } else if (scrollV > dataBoxs1Y) {
        panels[1].classList.add('act')
      } else {
        panels[0].classList.add('act')
      }


    });


    let target0, target1, target2, target3, target4, target5

    const getTargetVolume = () => {
      target0 = dataBoxs[0].getBoundingClientRect().top - dataArea.getBoundingClientRect().top + 1
      target1 = dataBoxs[1].getBoundingClientRect().top - dataArea.getBoundingClientRect().top + 1
      target2 = dataBoxs[2].getBoundingClientRect().top - dataArea.getBoundingClientRect().top + 1
      target3 = dataBoxs[3].getBoundingClientRect().top - dataArea.getBoundingClientRect().top + 1
      target4 = dataBoxs[4].getBoundingClientRect().top - dataArea.getBoundingClientRect().top + 1
      target5 = dataBoxs[5].getBoundingClientRect().top - dataArea.getBoundingClientRect().top + 1
    }
    getTargetVolume()


    window.addEventListener("resize", () => {
      dataArea.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setTimeout(() => {
        getTargetVolume();
      }, 1000);
    });



    panels.forEach((element, index) => {
      element.addEventListener('click', () => {

        if (index == 0) {
          dataArea.scrollTo({
            top: target0,
            behavior: 'smooth',
          });
        }

        if (index == 1) {
          dataArea.scrollTo({
            top: target1,
            behavior: 'smooth',
          });
        }

        if (index == 2) {
          dataArea.scrollTo({
            top: target2,
            behavior: 'smooth',
          });
        }

        if (index == 3) {
          dataArea.scrollTo({
            top: target3,
            behavior: 'smooth',
          });
        }

        if (index == 4) {
          dataArea.scrollTo({
            top: target4,
            behavior: 'smooth',
          });
        }

        if (index == 5) {
          dataArea.scrollTo({
            top: target5,
            behavior: 'smooth',
          });
        }

      });
    });


    console.log(dataBoxs[0].getBoundingClientRect().top);

    // window.scrollTo({
    //   top: target,
    //   behavior: 'smooth',
    // });




  }

})()
export default aboutHistoryScroll;