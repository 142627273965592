const funcNav = (() => {

  let nav = document.getElementById('jsNav');
  let navBtn = document.getElementById('jsNavBtn');
  let jsHeader = document.getElementById("jsHeader");
  let logoBox = jsHeader.querySelector(".js-logo-box");
  
  navBtn.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    if( navBtn.classList.contains('act') == true ){
      nav.classList.remove('act');
      navBtn.classList.remove('act');
      logoBox.classList.remove('act');
    }else{
      nav.classList.add('act');
      navBtn.classList.add('act');
      logoBox.classList.add('act');
    }
  });

})();
export default funcNav;