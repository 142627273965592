const topMain = () => {

  const pageTop = document.getElementById('pageTop');
  if (pageTop) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    const jsSecMain = document.getElementById("jsSecMain");
    const mainWrap = jsSecMain.querySelector('.js-main-wrap');
    const logoBox = mainWrap.querySelector('.js-logo-box');
    const starWrap = jsSecMain.querySelector('.js-star-wrap');


    if (sW <= 750) {
      jsSecMain.style.height = `${sH}px`
    }

    setTimeout(() => {
      logoBox.classList.add('act')
    }, 500);

    setTimeout(() => {
      starWrap.classList.add('act')
    }, 500);



  }

};
export default topMain;