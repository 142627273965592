const comHeader = (() => {

  const jsHeader = document.getElementById('jsHeader');
  if (jsHeader) {



    let sW = window.innerWidth;
    let sH = window.innerHeight;

    const jsHeader = document.getElementById("jsHeader");
    const jsNav = document.getElementById("jsNav");

    if (sW > 750) {

      const liP = jsHeader.querySelectorAll(".js-li-p");
      liP.forEach((element, index) => {

        element.addEventListener('mouseover', () => {
          const ulC = element.querySelector(".js-ul-c");
          element.classList.add('act')
          if (ulC) {
            ulC.classList.add('act')
          }
        });

        element.addEventListener('mouseleave', () => {
          const ulC = element.querySelector(".js-ul-c");
          element.classList.remove('act')
          if (ulC) {
            ulC.classList.remove('act')
          }
        });

      });

    } else {

      const spTriggerBtn = jsHeader.querySelectorAll(".js-sp-trigger-btn");
      const ulC = jsHeader.querySelectorAll(".js-ul-c");
      spTriggerBtn.forEach((element, index) => {

        let ulCH = []
        ulCH[index] = ulC[index].clientHeight;
        // console.log(ulCH[index]);
        ulC[index].style.height = `0px`


        element.addEventListener('click', () => {

          if (element.classList.contains('act') == true) {
            element.classList.remove('act');
            ulC[index].style.height = `0px`
          } else {
            element.classList.add('act');
            ulC[index].style.height = `${ulCH[index]}px`
          }
        });

      });

    }



    gsap.to(jsHeader, {
      scrollTrigger: {
        trigger: 'body',
        start: 'top top',
        onEnter: () => {
          jsHeader.classList.remove('scroll-top')
        },
        onLeaveBack: () => {
          jsHeader.classList.add('scroll-top')
        },
        // markers: true,
      }
    });

    const pageTop = document.getElementById('pageTop');
    if (pageTop) {
      const secInfo = document.getElementById('jsSecInfo');
      gsap.to(jsHeader, {
        scrollTrigger: {
          trigger: secInfo,
          start: 'top top',
          onEnter: () => {
            jsHeader.classList.add('act-logo')
          },
          onLeaveBack: () => {
            jsHeader.classList.remove('act-logo')
          },
          // markers: true,
        }
      });
    }

    gsap.to(jsNav, {
      scrollTrigger: {
        trigger: 'body',
        start: 'top top',
        onEnter: () => {
          jsNav.classList.remove('scroll-top')
        },
        onLeaveBack: () => {
          jsNav.classList.add('scroll-top')
        },
        // markers: true,
      }
    });



  }

})()
export default comHeader;