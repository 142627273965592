const topBg = (() => {

  const pageTop = document.getElementById('pageTop');
  if (pageTop) {


    const jsBgSecMain = document.getElementById('jsBgSecMain');
    const jsSecMain = document.getElementById('jsSecMain');

    const jsBgSecInfo = document.getElementById('jsBgSecInfo');
    const jsSecInfo = document.getElementById('jsSecInfo');

    const jsBgSecServiceAbout = document.getElementById('jsBgSecServiceAbout');
    const jsSecService = document.getElementById('jsSecService');
    const jsSecAbout = document.getElementById('jsSecAbout');

    const jsBgSecRecordContact = document.getElementById('jsBgSecRecordContact');
    const jsSecRecord = document.getElementById('jsSecRecord');
    const jsSecContents = document.getElementById('jsSecContents');

    // const jsBgSecHistory = document.getElementById('jsBgSecHistory');
    // const jsSecHistory = document.getElementById('jsSecHistory');

    // const jsBgSecContact = document.getElementById('jsBgSecContact');
    const jsSecContact = document.getElementById('jsSecContact');

    const jsContentContent = document.getElementById('jsContentContent');
    const main = document.getElementById('main');


    const bgSet = () => {
      let jsSecMainH = jsSecMain.clientHeight
      jsBgSecMain.style.height = `${jsSecMainH}px`

      let jsSecInfoH = jsSecInfo.clientHeight
      jsBgSecInfo.style.height = `${jsSecInfoH}px`

      let jsSecServiceH = jsSecService.clientHeight
      let jsSecAboutH = jsSecAbout.clientHeight
      jsBgSecServiceAbout.style.height = `${jsSecServiceH + jsSecAboutH * 0.5}px`
      jsBgSecServiceAbout.style.marginBottom = `${jsSecAboutH * 0.5}px`

      let jsSecRecordH = jsSecRecord.clientHeight
      let jsSecContentsH = jsSecContents.clientHeight
      let jsSecContactH = jsSecContact.clientHeight
      jsBgSecRecordContact.style.height = `${jsSecRecordH + jsSecContentsH + jsSecContactH}px`

      // let jsBgSecHistoryH = jsSecHistory.clientHeight
      // jsBgSecHistory.style.height = `${jsBgSecHistoryH}px`

      // let jsSecContactH = jsSecContact.clientHeight
      // jsBgSecContact.style.height = `${jsSecContactH}px`

      let jsContentContentH = jsContentContent.clientHeight
      main.style.height = `${jsContentContentH}px`
    }


    bgSet()


    let time = 0
    const frame = () => {
      time = time + 1
      // console.log(time);
      if (time % 200 == 0) {
        bgSet()
      }
      window.requestAnimationFrame(frame);
    }
    window.requestAnimationFrame(frame);


    // window.addEventListener('load', () => {
    //   bgSet()
    // })


    // window.addEventListener("resize", () => {
    //   bgSet()
    // });



  }

})();
export default topBg;